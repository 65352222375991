.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: stretch;
}

.Cards {
  width: 100%;
  flex-grow: 1;
}

.FieldWrapper {
  width: 100%;
  flex-grow: 1;
}

.TitleField {
  background-color: inherit;
  color: white;
  border: none;
  display: block;
  width: 100%;
}

.LogLineField {
  background-color: inherit;
  color: white;
  font-size: small;
  flex-grow: 1;
  border: none;
  height: 100%;
}

.LogLineRow {
  flex-direction: row;
}

@media (orientation: portrait) {
  .LogLineRow {
    flex-direction: column;
  }
}