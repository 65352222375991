.CardTop {
    background-color: #ffffff;
}

.public-DraftEditorPlaceholder-inner { 
    position: absolute; 
    pointer-events: none; 
    color: gray;
} 

.EditorWrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    text-align: left;
}

#MainCard .ql-editor {
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 38pt;
    font-style: bolder;
    font-weight: 500;
    line-height: 90%;
    color: black;
    white-space: pre-wrap;
}

#MainCard .ql-editor > p {
    margin-bottom: 0px;
}

.ql-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#MainCard .ql-toolbar {
    position: absolute;
    background-color: white;
    border: solid;
    top: -46px;
    left: -2px;
}

#DetailsView .ql-toolbar {
    position: absolute;
    background-color: white;
    /* border: none; */
    top: 4px;
    right: 6px;
    padding-left: 12px;
}

.ql-expanded {
    z-index: 2;
}

#DetailsView .ql-editor {
    font-size: 13pt;
    color: black;
}

.ql-editor {
    cursor: text;
    flex-grow: 1;
}

.ql-editor a {
    color: blue;
}

.ql-editor.ql-blank::before {
    color: #999999;
    content: attr(data-placeholder);
}

.ql-card-border::before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f853";
}

.ql-formats button {
    transform: scale(1.4);
}

.ql-formats .ql-picker {
    transform: scale(1.4);
}

.ql-formats select {
    transform: scale(1.1);
}

.ql-tooltip {
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 100%; */
    font-size: xx-large;
    /* transform: scale(1); */
  }

strong {
    font-weight: 800;
}