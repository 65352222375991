.Main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items:stretch;
}

.MainArea {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
  
.LogoWide {
  width: 200px;
  height: 50px;
  flex-shrink: 0;
}

.LogoSquare {
  width: 50px;
  height: 50px;
}

@media (max-height: 500px) and (min-width: 500px) and (orientation: landscape) {
  .MainArea {
    flex-direction: row;
    align-items: flex-start;
  }
}
