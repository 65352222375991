.OutlineTop {
    align-items: flex-start;
    margin-left: 10px;
}

@media (max-width: 700px) {
    .OutlineTop {
        align-items: stretch;
        margin-left: 0;
    }
}