.TopBar {
    display: flex;
    flex-direction: row;
    flex-basis: flex-start;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 1;
}

.LogoWide {
    width: 140px;
    height: 35px;
    flex-shrink: 0;
}

.LogoSquareLink {
    display: none;
}

.MenuAndUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@keyframes changeOpacity {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
}

#OngoingUpdate {
    animation-duration: 0.5s;
    animation-name: changeOpacity;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

#NavMenu {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

@media (max-width: 500px) {
    #UserNameText {
        display: none;
    }
}

@media (max-height: 500px) and (min-width: 500px) and (orientation: landscape) {
    .TopBar {
        flex-direction: column;
        justify-content: flex-start;
    }

    #OngoingUpdateText {
        display: none;
    }

    .LogoWideLink {
        display: none;
    }

    .LogoSquareLink {
        display: inline;
    }

    #NavMenu {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 16px !important;
    }

    .MenuAndUser {
        padding-top: 16px;
        flex-direction: column-reverse;
        text-align: center;
    }

    #UserNameText {
        display: none;
    }
}
