.Front {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    align-self: center;
}

.Jumbo {
    display: flex;
    flex-direction: row;
}

.ScreenShots {
    flex-direction: row;
    align-self: stretch;
    align-items: flex-end;
}

.WriterGraphic {
    width: 500px;
    height: 500px;
    margin-left: 32px;
    flex-shrink: 0;
}
  
.LogoSquareFront {
    width: 50px;
    height: 50px;
}

.Centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-height: 500px) {
    .Jumbo {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .WriterGraphic {
        width: 260px;
        height: 260px;
        margin-left: 10;
    }
}

@media (max-width: 1000px) {
    .WriterGraphic {
        margin-left: 0;
        margin-bottom: 10px;
        width: 260px;
        height: 260px;
    }
}

@media (max-width: 500px) and (orientation: portrait) {
    .Jumbo {
        padding-top: 10px;
        padding-left: 10px;
        flex-direction: column-reverse;
        align-items: center
    }

    .ScreenShots {
        flex-direction: column;
        align-items: center;
    }
}