.Board {
    background-color: inherit;
}

.RowLine {
    display: flex;
    align-items: center;
    border: solid;
    color: grey;
    z-index: -1;
}

.ActHeader {
    transform: rotate(-90deg);
    font-size: 110px;
    margin-left: -70px;
    font-weight: bolder;
}